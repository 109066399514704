import React, { useState } from "react";
import { Info, Plus, XCircle } from "react-feather";
import Modal from "../../../../../components/Modal";
import { circleMembership, getShortRoleLabel, CIRCLE_STATUS, CIRCLE_MODE } from "../../../../../utils/constants";
import AddMember from "./AddMember";
import styles from '../ViewCase.module.scss';
import MemberItem from "./MemberItem";
import ParticipantEdit from "./ParticipantEdit";

const Members = ({ circle }) => {

    const [newMember, setNewMember] = useState(false);
    const [viewInfo, setViewInfo] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);

    const [addSupporting, setAddSupporting] = useState(null);

    const handleRoleChange = ({ target: { value } }) => setSelectedRole(value);

    const noMembersAdded = circle.participants.length === 0 && circle.responsibleParties.length === 0 && circle.affectedParties.length === 0 && circle.communityMembers.length === 0;

    const handleCloseMember = () => {
        setNewMember(false);
        setSelectedRole(null);
    }

    const handleAddMember = () => {
        setNewMember(true);
        if (circle.mode !== CIRCLE_MODE.restorative) {
            setSelectedRole(circleMembership.participant)
        }
    }



    const renderRoleInfo = role => {
        switch (role) {
            case 'RP':
                return (
                    <>
                        <p className="bold lead mb-2">Responsible Party</p>
                        <p className="mb-2">This is usually the person or people that are responsible for the harm or issue</p>
                        <p>By default all Responsible Parties will take our empathy test to generate a persona.</p>
                    </>
                )
            case 'AP':
                return (
                    <>
                        <p className="bold lead mb-2">Affected Party</p>
                        <p className="mb-2">This is usually the main person or people that have experienced the harm or issue.</p>
                        <p>By default all Affected Parties will take our empathy test to generate a persona</p>
                    </>
                )
            case 'CM':
                return (
                    <>
                        <p className="bold lead mb-2">Community Member</p>
                        <p className="mb-2">This is usually an outside person that brings another perspective. Common examples are police officers, social workers, company management etc.</p>
                        <p>By default support members do not take our empathy test to generate a persona.</p>
                    </>
                )
            case 'SM':
                return (
                    <>
                        <p className="bold lead mb-2">Support Member</p>
                        <p className="mb-2">A support member is there to assist a named person in the circle. These are usually family members, friends, foster carers, mentors etc.</p>
                        <p>By default support members do not take our empathy test to generate a persona.</p>
                    </>
                )
        }
    }

    if (newMember) {
        return (
            <>
                <div className="mb-2 d-flex align-items-center">
                    <button onClick={handleCloseMember} type="button" className="mr-2"><XCircle /></button>
                    <h1>Add member</h1>
                </div>
                {
                    selectedRole !== circleMembership.participant &&
                    <div className="d-flex align-items-center">
                        <p className="uppercase text-gray-2 bold small mr-2">Please Choose a Role Type</p>
                        <select className="width-auto flex-grow-0 basic-form__text-select" onChange={handleRoleChange}>
                            <option value=''>Select role</option>
                            <option value={circleMembership.responsibleParty}>Responsible party</option>
                            <option value={circleMembership.affectedParty}>Affected party</option>
                            <option value={circleMembership.communityMember}>Community member</option>
                        </select>
                        {selectedRole && <span className="label label-muted ml-2">{getShortRoleLabel(+selectedRole)}</span>}
                    </div>
                }
                {selectedRole && <AddMember close={handleCloseMember} role={+selectedRole} circle={circle} />}
            </>
        )
    }

    if (addSupporting) {
        return (
            <>
                <div className="mb-2 d-flex align-items-center">
                    <button onClick={() => setAddSupporting(null)} type="button" className="mr-2"><XCircle /></button>
                    <h1>Add support member {addSupporting && <>for {addSupporting?.user.firstName}</>}</h1>
                </div>
                <AddMember
                    supportingUserId={addSupporting?.id}
                    close={() => setAddSupporting(null)}
                    role={circleMembership.supportMember}
                    circle={circle}
                />
            </>
        )
    }


    if (circle.referral && circle.participants[0]?.user?.needsConfirmation) {
        return (
            <div className="row">
                <div className="col-md-8">
                    <ParticipantEdit needsConfirmation={true} circle={circle} role={circle.participants[0].role} member={circle.participants[0]} />
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="mb-2 d-flex align-items-center justify-content-between">
                <h1>Case Members</h1>
                {circle.status < CIRCLE_STATUS.opening &&
                    <button type="button" className="btn d-flex px-1" onClick={handleAddMember}><Plus /></button>
                }
            </div>

            {noMembersAdded ?
                <div className="p-3 bg-gray">
                    <div className="row">
                        <div className="col-md-6">
                            <p className="lead bold">You need at least 1 member in a case.</p>
                            {circle.mode === CIRCLE_MODE.restorative &&
                                <>
                                    <p className="mb-2 mt-2">We recommend that affected and responsible parties take the built in empathy test. This will help circle leaders to understand and adjust their approach to these individuals.</p>
                                    <p>You can add as many Affected, Responsible and Community Members as you need. In addition, affected and Responsible parties can be allocated support members.</p>
                                </>
                            }
                        </div>
                        {circle.mode === CIRCLE_MODE.restorative &&

                            <div className="col-md-6">
                                <p className="lead bold mb-2">Available Roles:</p>
                                <ul>
                                    <li className="mb-1 d-flex align-items-center justify-content-between">
                                        <p className="bold"> <span className="label label-muted mr-1">RP</span> Responsible Party</p>
                                        <button type="button" onClick={() => setViewInfo('RP')}><Info /></button>
                                    </li>
                                    <li className="mb-1 d-flex align-items-center justify-content-between">
                                        <p className="bold"> <span className="label label-muted mr-1">AP</span> Affected Party</p>
                                        <button type="button" onClick={() => setViewInfo('AP')}><Info /></button>
                                    </li>
                                    <li className="mb-1 d-flex align-items-center justify-content-between">
                                        <p className="bold"> <span className="label label-muted mr-1">SM</span> AP/RP Support Member</p>
                                        <button type="button" onClick={() => setViewInfo('SM')}><Info /></button>
                                    </li>
                                    <li className="mb-1 d-flex align-items-center justify-content-between">
                                        <p className="bold"> <span className="label label-muted mr-1">CM</span> Community Member</p>
                                        <button type="button" onClick={() => setViewInfo('CM')}><Info /></button>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div> :
                <div className="row">
                    <div className="col-md-8">
                        <div className={styles.member}>
                            {circle.mode === CIRCLE_MODE.restorative &&
                                <div className={styles.memberLabel}>Role</div>
                            }
                            <div className={styles.memberName}>Name</div>
                            <div className={`${styles.memberSide} ${circle.status >= CIRCLE_STATUS.opening ? styles.memberSideStarted : ''} small mr-1`}>
                                <div className={styles.memberPersona}>Persona</div>
                                {circle.status < CIRCLE_STATUS.opening &&
                                    <>

                                        <div className={styles.memberLabel}>Edit</div>
                                        <div className={styles.memberLabel}>Delete</div>
                                    </>
                                }
                            </div>
                        </div>
                        {circle.responsibleParties?.length > 0 && circle.responsibleParties.map(responsibleParty =>
                            <div key={responsibleParty.id}>
                                <MemberItem circle={circle} member={responsibleParty} />
                                {responsibleParty.supportingUsers.map(member =>
                                    <MemberItem supportingUserId={member.id} key={member.id} isSupport={'RP'} member={member} circle={circle} />
                                )}

                                {circle.status < CIRCLE_STATUS.opening &&
                                    <button type="button" onClick={() => setAddSupporting(responsibleParty)} className="d-flex align-items-center mb-2 ml-1">
                                        <span className="btn d-flex align-items-center px-1 mr-1"><Plus size={14} /></span>Add Support Member for {responsibleParty.user?.firstName}
                                    </button>
                                }
                            </div>
                        )}

                        {circle.affectedParties?.length > 0 && circle.affectedParties.map(affectedParty =>
                            <div key={affectedParty.id}>
                                <MemberItem circle={circle} member={affectedParty} />
                                {affectedParty.supportingUsers.map(member =>
                                    <MemberItem supportingUserId={member.id} key={member.id} isSupport={'AP'} circle={circle} member={member} />
                                )}
                                {circle.status < CIRCLE_STATUS.opening &&
                                    <button type="button" onClick={() => setAddSupporting(affectedParty)} className="d-flex align-items-center mb-2 ml-1">
                                        <span className="btn d-flex align-items-center px-1 mr-1"><Plus size={14} /></span>Add Support Member for {affectedParty.user?.firstName}
                                    </button>
                                }
                            </div>
                        )}
                        {circle.communityMembers.map(member =>
                            <MemberItem key={member.id} circle={circle} member={member} />
                        )}

                        {circle.participants.map(member =>
                            <MemberItem key={member.id} circle={circle} member={member} />
                        )}
                    </div>
                    {circle.mode === CIRCLE_MODE.restorative &&
                        <div className="col-md-4">
                            <p className="lead bold mb-2">Available Roles:</p>
                            <ul>
                                <li className="mb-1 d-flex align-items-center justify-content-between">
                                    <p className="bold"> <span className="label label-muted mr-1">RP</span></p>
                                    <button type="button" onClick={() => setViewInfo('RP')}><Info /></button>
                                </li>
                                <li className="mb-1 d-flex align-items-center justify-content-between">
                                    <p className="bold"> <span className="label label-muted mr-1">AP</span></p>
                                    <button type="button" onClick={() => setViewInfo('AP')}><Info /></button>
                                </li>
                                <li className="mb-1 d-flex align-items-center justify-content-between">
                                    <p className="bold"> <span className="label label-muted mr-1">SM</span></p>
                                    <button type="button" onClick={() => setViewInfo('SM')}><Info /></button>
                                </li>
                                <li className="mb-1 d-flex align-items-center justify-content-between">
                                    <p className="bold"> <span className="label label-muted mr-1">CM</span></p>
                                    <button type="button" onClick={() => setViewInfo('CM')}><Info /></button>
                                </li>
                            </ul>
                        </div>
                    }

                </div>
            }


            {viewInfo && (
                <Modal closeModal={() => setViewInfo(null)}>
                    {renderRoleInfo(viewInfo)}
                </Modal>
            )}
        </>

    )
}

export default Members;