import React, { useState, useRef, useEffect } from "react";
import RangeCalendar from "../../../components/RangeCaldendar";
import { useQuery } from "@apollo/react-hooks";
import { GET_OFFICE_LEADERS } from "../../Cases/services/grapqhl";
import moment from "moment";
import { CheckSquare, Square } from "react-feather";
import styles from '../CaseReports.module.scss';

const statusFilters = ['Overdue', 'Requires Review', 'Due', 'Scheduled Soon', 'Done', 'Missed'];

const CustomFilters = ({ onApplyCustomFilters, officeId }) => {


    const [showMentor, setShowMentor] = useState(false);
    const [showDate, setShowDate] = useState(false);
    const [showStatus, setShowStatus] = useState(false);

    const [customFilters, setCustomFilters] = useState({
        mentor: '',
        date: '',
        status: []
    });

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null
    });

    const { data, loading, error } = useQuery(GET_OFFICE_LEADERS, {
        variables: {
            officeId
        }
    });

    const refMentor = useRef(null);
    const refDate = useRef(null);
    const refStatus = useRef(null);


    const handleClickOutside = (event) => {
        if (refMentor.current && !refMentor.current.contains(event.target)) {
            setShowMentor(false);
        }

        if (refDate.current && !refDate.current.contains(event.target)) {
            setShowDate(false)
        }

        if (refStatus.current && !refStatus.current.contains(event.target)) {
            setShowStatus(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);




    const getMentorTitle = (leaders) => {
        const selectedLeader = leaders.find(el => el.id === customFilters.mentor);
        return customFilters.mentor === 'all' ? 'All mentors' : `${selectedLeader?.firstName} ${selectedLeader?.lastName}`
    }

    const getDatesTitle = () => {
        const dateFilter = customFilters.date;
        switch (dateFilter) {
            case 'last_7':
                return 'Last 7 days';
            case 'last_30':
                return 'Last 30 days';
            case 'last_month':
                return 'Last calendar month';
            case 'next_14':
                return 'Next 14 days';
            case 'custom':
                return dateRange.startDate && dateRange.endDate ? `${moment(dateRange.startDate).format('DD/MM')} - ${moment(dateRange.endDate).format('DD/MM')}` : 'Choose dates'
            default:
                return ''
        }
    }


    const handleFilterSelect = (type, value) => {
        if (type === 'mentor') {
            setCustomFilters({ ...customFilters, mentor: value === 'all' ? null : value });
            setShowMentor(false);
        } else if (type === 'date') {
            setCustomFilters({ ...customFilters, date: value });
            value !== 'custom' && setShowDate(false);
        } else if (type === 'status') {
            if (value === 'all') {
                customFilters.status?.length === statusFilters.length ? setCustomFilters({ ...customFilters, status: [] }) : setCustomFilters({ ...customFilters, status: statusFilters });
            } else {
                if (customFilters.status.indexOf(value) === -1) {
                    setCustomFilters({ ...customFilters, status: [...customFilters.status, value] });
                } else {
                    setCustomFilters({ ...customFilters, status: [...customFilters.status.filter(el => el !== value)] });
                }
            }
        }
    }


    const onDone = () => {



        onApplyCustomFilters(customFilters, dateRange);
    }


    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return <p>Loading...</p>
    }

    if (data?.office?.leaders) {

        const leaders = data?.office?.leaders || [];

        return (
            <>
                <div className="mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div ref={refMentor} className="relative d-block mr-2">
                                <button onClick={() => setShowMentor(!showMentor)} className="basic-form__text-select width-auto">
                                    {customFilters.mentor ? getMentorTitle(leaders) : 'All mentors'}
                                </button>
                                {showMentor && (
                                    <div className={styles.filterMenu}>
                                        <ul>
                                            <li className="mb-2"><button className="d-flex align-items-center" onClick={() => handleFilterSelect('mentor', 'all')}><span className={`${styles.radioSelect} ${!customFilters.mentor ? styles.radioSelectActive : ''}`}></span>All mentors</button></li>
                                            <li className="separator"></li>
                                            {leaders.map(leader =>
                                                <li key={leader.id} className="mb-2"><button className="d-flex align-items-center" onClick={() => handleFilterSelect('mentor', leader.id)}><span className={`${styles.radioSelect} ${customFilters.mentor === leader.id ? styles.radioSelectActive : ''}`}></span>{leader.firstName} {leader.lastName}</button></li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>

                            <div ref={refDate} className="relative d-block mr-2">
                                <button onClick={() => setShowDate(!showDate)} className="basic-form__text-select width-auto">
                                    {customFilters.date ? getDatesTitle() : 'Select dates'}
                                </button>
                                {showDate && (
                                    <div className={styles.filterMenu}>
                                        <ul>
                                            <li className="mb-2"><button className="d-flex align-items-center" onClick={() => handleFilterSelect('date', 'last_7')}><span className={`${styles.radioSelect} ${customFilters.date === 'last_7' ? styles.radioSelectActive : ''}`}></span>Last 7 days</button></li>
                                            <li className="mb-2"><button className="d-flex align-items-center" onClick={() => handleFilterSelect('date', 'last_30')}><span className={`${styles.radioSelect} ${customFilters.date === 'last_30' ? styles.radioSelectActive : ''}`}></span>Last 30 days</button></li>
                                            <li className="mb-2"><button className="d-flex align-items-center" onClick={() => handleFilterSelect('date', 'last_month')}><span className={`${styles.radioSelect} ${customFilters.date === 'last_month' ? styles.radioSelectActive : ''}`}></span>Last Calendar Month</button></li>
                                            <li className="mb-2"><button className="d-flex align-items-center" onClick={() => handleFilterSelect('date', 'next_14')}><span className={`${styles.radioSelect} ${customFilters.date === 'next_14' ? styles.radioSelectActive : ''}`}></span>Next 14 days</button></li>
                                            <li className="mb-2"><button className="d-flex align-items-center" onClick={() => handleFilterSelect('date', 'custom')}><span className={`${styles.radioSelect} ${customFilters.date === 'custom' ? styles.radioSelectActive : ''}`}></span>Custom dates</button></li>
                                            {customFilters.date === 'custom' && (
                                                <div className={styles.customCal}>
                                                    <RangeCalendar
                                                        dateRange={dateRange}
                                                        onSetRanges={setDateRange}
                                                        showTextLabels={true}
                                                    />
                                                    <button disabled={!dateRange.startDate || !dateRange.endDate} onClick={() => setShowDate(false)} className="btn btn-outline">Done</button>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>

                            <div ref={refStatus} className="relative d-block">
                                <button onClick={() => setShowStatus(!showStatus)} className="basic-form__text-select width-auto">
                                    {customFilters.status ? `Report status ${customFilters.status?.length > 0 ? `(${customFilters.status?.length})` : ''} ` : 'Select status'}
                                </button>
                                {showStatus && (
                                    <div className={styles.filterMenu}>
                                        <ul>
                                            <li className="mb-2"><button className="d-flex align-items-center" onClick={() => handleFilterSelect('status', 'all')}>{statusFilters.length === customFilters.status.length ? <CheckSquare className="mr-1" /> : <Square className="mr-1" />} All/None</button></li>
                                            <li className="separator"></li>
                                            {statusFilters.map(el => (
                                                <li key={el} className="mb-2"><button className="d-flex align-items-center" onClick={() => handleFilterSelect('status', el)}>{customFilters.status.indexOf(el) > -1 ? <CheckSquare className="mr-1" /> : <Square className="mr-1" />} {el}</button></li>
                                            ))}
                                        </ul>
                                        <button disabled={customFilters.status?.length === 0} onClick={() => setShowStatus(false)} className="btn btn-outline">Done</button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <button disabled={customFilters.status.length === 0} className="btn btn-primary" onClick={() => onDone()}>Apply</button>
                    </div>
                </div>
            </>
        )
    }
    return null;
}

export default CustomFilters;