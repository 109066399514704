import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RangeCalendar = ({ onSetRanges, dateRange, showTextLabels }) => {

    return (
        <div>
            {showTextLabels && <span className='u-m-right-1'>From:</span>}
            <DatePicker
                dateFormat='dd/MM/yyyy'
                className="picker basic-form__text-box"
                selected={dateRange.startDate && dateRange.startDate}
                selectsStart
                startDate={dateRange.startDate && dateRange.startDate}
                placeholderText='Start date'
                endDate={dateRange.endDate}
                onChange={(date) => onSetRanges({ ...dateRange, startDate: date })}
            />
            {showTextLabels && <span className='u-m-right-1'>To:</span>}

            <DatePicker
                dateFormat='dd/MM/yyyy'
                className="picker basic-form__text-box"
                selected={dateRange.endDate && dateRange.endDate}
                placeholderText='End date'
                selectsEnd
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                onChange={(date) => onSetRanges({ ...dateRange, endDate: date })}
            />
        </div>
    )
}
export default RangeCalendar;