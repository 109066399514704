import React, { useState } from "react";
import styles from '../CaseReports.module.scss';
import { AlertTriangle, Calendar, CheckCircle, Clock, Inbox, Slash } from "react-feather";
import moment from "moment";
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import SideModal from "../../../components/SideModal";
import ViewSession from "../../Cases/scenes/ViewCase/components/NotesAndDocs/ViewSession";
import { DEBRIEF_STATUS, SESSION_STATUS } from "../../../utils/constants";

const SessionItem = ({ session, setReports, reports, reportsOfficeId }) => {

    const [viewSession, setViewSession] = useState(null);

    const deadline = moment(session.date + ' ' + session.time, "YYYY-MM-DD HH:mm:ss");
    const now = moment();
    const diffInHours = now.diff(deadline, "hours");


    const renderIcon = () => {
        if (session.debrief?.status === DEBRIEF_STATUS.new) {
            return <Tooltip size="big" title='Requires Review (debrief submitted by mentor)'><span className='bg-blue'><Inbox size={16} /></span></Tooltip>
        }
        if (session.debrief?.status === DEBRIEF_STATUS.approved) {
            return <Tooltip size="big" title='Done/Complete (debrief submitted & approved by admin)'><span className='bg-success'><CheckCircle size={16} /></span></Tooltip>

        }
 

        if (diffInHours >= 0 && diffInHours < 24 && !session.debrief) {
            return <Tooltip size="big" title='Report Due'><span className='bg-gray-2'><Clock size={16} /></span></Tooltip>

        }

        if (diffInHours >= 24 && !session.debrief) {
            return <Tooltip size="big" title='Report Overdue'><span className='bg-danger'><AlertTriangle size={16} /></span></Tooltip>
        }

        if (moment(session.date).isAfter(moment())) {
            return <Tooltip size="big" title='Schedulled Session'><span className='bg-gray-muted'><Calendar size={16} /></span></Tooltip>
        }
        if (session.status === SESSION_STATUS.missed) {
            return <Tooltip size="big" title='Missed report submitted'><span className='bg-warning'><Slash size={16} /></span></Tooltip>
        }

        return null;

    }
    return (
        <>
            <li style={{cursor:'pointer'}} onClick={() => setViewSession(session)} className={`list-item list-item--separator-dark ${styles.session}`}>
                <div className={styles.sessionName}>
                    {session.circle.name}
                </div>
                <div className={styles.sessionCircle}>
                    {session.leadMentor}
                </div>
                <div className={styles.sessionDate}>
                    <span className="label label-primary-light mr-1">S{session.index}</span>
                    {moment(session.date).format('DD/MM')} @ {moment(session.time, 'HH:mm:ss').format('HH:mm')}
                </div>
                <div className={`${styles.sessionStatus} `}>
                    {renderIcon()}
                </div>
            </li>

            {viewSession && (
                <SideModal closeModal={() => setViewSession(null)}>
                    <ViewSession reportsOfficeId={reportsOfficeId} reports={reports} setReports={setReports} sessionId={viewSession.id} circle={viewSession.circle} />
                </SideModal>
            )}
        </>
    )
}

export default SessionItem;