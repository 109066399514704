import React from "react";
import { REFERRAL_STATUS } from "../../../utils/constants";
import moment from "moment";
import styles from '../Referrals.module.scss'


const ReferralItem = ({ referral, setViewRef }) => {
    return (
        <li className={` list-item--separator-dark pb-2 mb-2`}>
            <button onClick={() => setViewRef(referral)} className={`${styles.item} `}>
                <div className={styles.itemDate}>{moment(referral.referralDate).format('DD MMM YYYY')}</div>
                <div className={styles.itemMain}>
                    <p className="lead">{referral.youngPersonFirstName} {referral.youngPersonLastName}</p>
                    {referral.circle ?
                        <p>Case: {referral.circle.name} | <span>Case lead: {referral.circle.leader?.user?.firstName} {referral.circle.leader?.user?.lastName}</span></p> :
                        referral.addedBy ?
                            <p>
                                Added by: {referral.addedBy.firstName} {referral.addedBy.lastName} | {moment(referral.createdAt).format('DD MMM YYYY')} @ {moment(referral.createdAt).format('HH:mm')}
                            </p> : <p>Referred by: {referral.referrerFirstName} {referral.referrerLastName} | <span>Company: {referral.referrerCompany || '-'} |  Dept: {referral.referrerDepartment || '-'}</span></p>
                    }
                </div>
                <div className={styles.itemSide}>
                    {referral.office && <span className="label label-muted mr-1">{referral.office.name}</span>}
                    {referral.status === REFERRAL_STATUS.new && <span className="label label-primary">New</span>}
                </div>
            </button>
        </li>
    )
}

export default ReferralItem;