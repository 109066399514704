import { useState, useMemo, useEffect } from 'react';
import { useDebounce } from './useDebounce';

export const useFilteredList = (items, delay = 0, fieldName = 'name') => {

    const [term, setTerm] = useState('');
    const [ready, setReady] = useState(false);

    const debouncedTerm = useDebounce(term, delay);

    useEffect(() => {
        setReady(true);
    }, [debouncedTerm]);

    const handleTermChange = ({ target: { value } }) => {
        setTerm(value);
        setReady(false);
    }

    const itemsToShow = useMemo(() => {
        return debouncedTerm === '' ? items : items.filter(el => (el[fieldName] || '').toLowerCase().includes(debouncedTerm.toLowerCase()));
    }, [debouncedTerm, items]);

    return {
        itemsToShow: ready ? itemsToShow : [],
        handleTermChange,
        term,
        filteringReady: ready
    }
}

