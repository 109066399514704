import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_CASE_NOTES, DELETE_CASE_NOTE } from "../../../../services/grapqhl";
import Loader from "../../../../../../components/Loader";
import { PlusCircle, File, Trash2, Edit } from "react-feather";
import Modal from "../../../../../../components/Modal";
import AddNote from "./AddNote";
import TruncateText from "../../../../../../components/TruncateText";
import FormErrors from "../../../../../../components/FormErrors";
import EditNote from "./EditNote";
import { CIRCLE_STATUS } from "../../../../../../utils/constants";
import axios from 'axios'
import styles from '../../ViewCase.module.scss';

const CaseNotes = ({ circle }) => {

    const [addNote, setAddNote] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [orderType, setOrderType] = useState('mostRecent');

    const [confirmDelete, setConfirmDelete] = useState(null);
    const [editNote, setEditNote] = useState(null);


    const { data, loading, error } = useQuery(GET_CASE_NOTES, {
        variables: {
            circleId: circle.id
        }
    })

    const [deleteCaseNote, { loading: deleting }] = useMutation(DELETE_CASE_NOTE, {
        onCompleted: (data) => {
            if (data.deleteCaseNote?.errors?.length > 0) {
                setFormErrors(data.deleteCaseNote.errors)
                return
            }
            if (data?.deleteCaseNote?.deleted) {
                setConfirmDelete(null);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_CASE_NOTES,
                variables: {
                    circleId: circle.id,
                },
                fetchPolicy: 'network-only'
            }
        ],
    })



    const onDownloadFile = (url) => {
        axios({
            method: 'get',
            url: url,
            responseType: 'blob'
        }).then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = downloadUrl;

            // Extract the filename from the Content-Disposition header or use a default name
            const contentDisposition = response.headers['content-disposition'];
            let filename = url.split('/').pop();


            if (contentDisposition) {
                const matches = /filename="?(.+)"?/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1];
                }
            }

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

        }).catch((error) => {
            console.error('Failed to download file:', error);
        });
    }

    const Note = ({ note }) => {
        return (
            <div className="mb-1 bg-white p-1">
                <div className="p-1">

                    <div className="mb-1"><TruncateText str={note.text} /></div>
                    {note.documents?.map(document =>
                        <button key={document.id} onClick={() => onDownloadFile(document.url)} className="btn btn-outline d-flex align-items-center mb-1"><File className="mr-1" size={20} />{document.name}</button>
                    )}

                    <div className={`d-flex align-items-center ${styles.noteSep}`}>
                        <div>
                            {note.author && <p className="small">{note.author.firstName} {note.author.lastName}</p>}
                        </div>
                        <div className="ml-auto d-flex align-items-center">
                            <p className="small mr-1">{note.createdAt}  </p>
                            <button className="mr-1" onClick={() => setEditNote(note)}><Edit size={18} /></button>
                            <button onClick={() => setConfirmDelete(note)}><Trash2 size={18} /></button>
                        </div>
                    </div>
                    {formErrors && <FormErrors errors={formErrors} />}
                </div>

            </div>
        )
    }

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.circle?.caseNotes) {

        const { circle } = data;


        return (
            <>
                <div className="d-flex mb-3">
                    <select defaultValue={orderType} onChange={(e) => setOrderType(e.target.value)} className="basic-form__text-select width-auto flex-grow-0 mb-0">
                        <option value='mostRecent'>Most recent</option>
                        <option value='oldest'>Oldest</option>
                    </select>
                    {circle.status < CIRCLE_STATUS.closed_outcomes_met &&
                        <button className="btn d-flex ml-auto" onClick={() => setAddNote(true)}><PlusCircle /></button>
                    }
                </div>


                {circle.caseNotes.length > 0 ?
                    <>
                        {orderType === 'mostRecent' ? circle.caseNotes.map(note => <Note key={note.id} note={note} />) : [...circle.caseNotes].reverse().map(note => <Note key={note.id} note={note} />)}
                    </> :
                    <div>
                        <p className="bold lead mb-1">Nothing to see here yet</p>
                        <p>Add notes and documents to the case</p>
                    </div>
                }



                {addNote &&
                    <Modal wide closeModal={() => setAddNote(false)}>
                        <AddNote circle={circle} close={() => setAddNote(false)} />
                    </Modal>
                }

                {confirmDelete &&
                    <Modal closeModal={() => setConfirmDelete(null)}>
                        <div className="text-center">
                            <h2 className="mb-3">Are you sure you want to delete the note?</h2>
                            <button disabled={deleting} className="btn mr-2" onClick={() => deleteCaseNote({
                                variables: {
                                    caseNoteId: confirmDelete.id
                                }
                            })}>Yes</button>
                            <button className="btn btn-muted" onClick={() => setConfirmDelete(null)}>No</button>
                        </div>
                    </Modal>
                }

                {editNote &&
                    <Modal closeModal={() => setEditNote(null)}>
                        <EditNote close={() => setEditNote(null)} note={editNote} circle={circle} />
                    </Modal>
                }
            </>
        )
    }
    return null;
}

export default CaseNotes;