import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_ORG_REFERRALS, GET_OFFICE_REFERRALS } from "./services/graphql";
import ReferralsContent from "./ReferralsContent";
import { AuthContext } from "../../services/auth/AuthProvider";

const Referrals = ({ officeId }) => {

    const { currentUser } = useContext(AuthContext);

    const { data, loading, error } = useQuery(GET_ORG_REFERRALS, {
        fetchPolicy: 'network-only',
        variables: {
            organizationId: currentUser?.organization?.id
        },
        skip: officeId
    });

    const { data: data2, loading: loading2, error: error2 } = useQuery(GET_OFFICE_REFERRALS, {
        fetchPolicy: 'network-only',

        variables: {
            officeId
        },
        skip: !officeId
    })

    if (loading || loading2) {
        return <p>Loading...</p>
    }

    if (error || error2) {
        return <p>{error?.message} || {error2?.message}</p>
    }

    if (data?.organization?.id || data2?.office?.id) {

        const orgReferrals = data?.organization?.referrals || [];
        const officeReferrals = data2?.office?.referrals || [];
        return (
            <ReferralsContent organization={currentUser.organization} referrals={officeId ? officeReferrals : orgReferrals} officeId={officeId} />
        )
    }


    return null;
}

export default Referrals;