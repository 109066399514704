import React, { useState } from "react";
import EditMembership from "./EditMembership";
import EditUser from "./EditUser";
import { Copy, Edit, XCircle, CheckCircle, Loader, Star, Award } from "react-feather";
import defaultImg from '../../../images/profile-default.svg';
import { useMutation, useQuery } from "@apollo/react-hooks";
import FormErrors from "../../../components/FormErrors";
import { useForm } from "react-hook-form";
import { EDIT_LEADER_PRACTICE, GET_ORG_USERS, QUALIFICATIONS_EXPERIENCES } from "../services/graphql";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import rjcLogo from '../../../images/members/rjc_logo.png';
import advancedImg from '../../../images/members/advanced.png';
import intermediateImg from '../../../images/members/intermediate.png';
import foundationImg from '../../../images/members/foundation.png';
import dbsUnknown from '../../../images/members/dbs_unknown.png';
import dbsSet from '../../../images/members/dbs_set.png';
import styles from '../Team.module.scss';
import styles2 from '../../../components/ProfileImage/ProfileImage.module.scss';
import QualificationsExperience from "./QualificationsExperience";

//const dummy = 'Bla bla staement\nMore line hre'

const employmentList = [
    'Paid',
    'Volunteer',
];

const practiceAreasList = [
    'Criminal Justice',
    'Education',
    'Social Care',
    "Health Care",
    'Human Resources',
    'Dispute Resolution'
];

const ViewMember = ({ close, organization, user, currentUser, setShowMember }) => {

    const isLeader = user.officeMemberships.some(m => m.isCircleLeader);

    const [expandedBio, setExpandedBio] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [copy, setCopy] = useState(null);

    const [editPractice, setEditPractice] = useState(false);
    const [mutationErrors, setMutationErrors] = useState([]);

    const { register, handleSubmit, formState: { errors } } = useForm();


    const [updatePractice, { loading }] = useMutation(EDIT_LEADER_PRACTICE, {
        update: (_, { data }) => {
            if (data?.updateLeaderProfileEmploymentAndAreaOfPractice?.errors?.length > 0) {
                setMutationErrors(data?.updateLeaderProfileEmploymentAndAreaOfPractice.errors)
                return;
            }
            if (data?.updateLeaderProfileEmploymentAndAreaOfPractice?.userProfile?.id) {
                setEditPractice(false);
                const newProfile = data?.updateLeaderProfileEmploymentAndAreaOfPractice?.userProfile;
                setShowMember({ ...user, userProfile: newProfile })
            }
        },
        refetchQueries: [{ query: GET_ORG_USERS, variables: { organizationId: organization.id, showAllUsers: true } }]

    })

    const renderBio = (bio) => {
        if (!bio) return;

        let visibleBio = '';
        const showReadMore = bio.length > 250 || bio.includes('\n'); 
        if (showReadMore) {
            const firstLine = bio.split('\n')[0];
            visibleBio = expandedBio ? bio : (firstLine.length < 250 ? firstLine : bio.substring(0, 250) + '\u2026')
        } else {
            visibleBio = bio;
        }

        return (
            <div>
                <p className="newlines">{visibleBio}</p>
                {showReadMore && (
                    <button
                        className="link-underline text-primary"
                        type="button"
                        onClick={() => setExpandedBio(!expandedBio)}
                    >read {expandedBio ? 'less' : 'more'}</button>
                )}
            </div>
        )
    }

    const renderRjcImage = () => {
        switch (user.userProfile?.rjcRegisteredStatusName) {
            case 'Foundation':
                return <img width={80} src={foundationImg} />;
            case 'Intemediate':
                return <img width={80} src={intermediateImg} />;
            case 'Advanced':
                return <img width={80} src={advancedImg} />;
            default:
                return null;
        }
    }

    const onSubmit = data => {
        const { employmentStatus, areaOfPractice } = data;
        updatePractice({
            variables: {
                userId: user.id,
                employmentStatus,
                areaOfPractice
            }
        });
    }


    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                    <button className="ml-auto" type="button" onClick={close}><XCircle /></button>
                </div>
                <div className="card-outline mb-2">
                    <div className="card-body">
                        {editUser ?
                            <EditUser setShowMember={setShowMember} organization={organization} user={user} close={() => setEditUser(null)} />
                            :
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        {user.photoUrl ?
                                            <div className={styles.circleImgWrapper}>
                                                <img className={`${styles.circleImg} mb-0 `} src={user.photoUrl} />
                                            </div>
                                            : <div className={styles.circleImgWrapper}><img className={styles.circleImg} src={defaultImg} /></div>}
                                        <div className="pl-2">
                                            <h1 className="bold mb-1">{user.firstName}</h1>
                                            <h2 className="h1 mb-1">{user.lastName}</h2>
                                            <p>{user.email}</p>
                                            {currentUser.isOrganizationAdmin &&
                                                <button className="mt-1 d-flex align-items-center" onClick={() => setEditUser(true)}><Edit className="mr-1" /> edit</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {renderBio(user.userProfile?.biography)}
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {
                    isLeader &&
                    <>
                        <div className="card-outline mb-2">
                            <div className="card-body">
                                {editPractice ?
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="employmentStatus">Employemnt Status</label>
                                                <select
                                                    {...register('employmentStatus', { required: 'Please select' })}
                                                    defaultValue={user.userProfile?.employmentStatus}
                                                    className="basic-form__text-select"
                                                >
                                                    <option value="">Please select</option>
                                                    {employmentList.map(el => <option key={el} value={el}>{el}</option>)}

                                                </select>
                                                {errors.employmentStatus && <p className="text-danger">{errors.employmentStatus.message}</p>}
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="employmentStatus">Primary Area of Practice</label>
                                                <select
                                                    {...register('areaOfPractice', { required: 'Please select' })}
                                                    defaultValue={user.userProfile?.areaOfPractice}
                                                    className="basic-form__text-select"
                                                >
                                                    <option value="">Please select</option>
                                                    {practiceAreasList.map(el => <option key={el} value={el}>{el}</option>)}
                                                </select>
                                                {errors.areaOfPractice && <p className="text-danger">{errors.areaOfPractice.message}</p>}
                                            </div>
                                            <div className="col-md-4 text-right d-flex align-items-center mt-2">
                                                <button type="submit" disabled={loading} className="btn mr-2">Save</button>
                                                <button type="button" onClick={() => setEditPractice(false)} className="btn btn-outline">Cancel</button>
                                            </div>
                                        </div>
                                        <FormErrors errors={mutationErrors} />
                                    </form> :
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            {currentUser.organization.logo && <img src={currentUser.organization.logo} width={50} />}
                                            <p className="ml-2">{currentUser.organization.name}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="mr-2">{user.userProfile?.employmentStatus} | {user.userProfile?.areaOfPractice}</p>
                                            {currentUser.isOrganizationAdmin &&
                                                <button onClick={() => setEditPractice(true)} className="d-flex align-items-center"><Edit className="text-primary mr-1" size={20} /> Edit</button>}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-6 d-flex">
                                <div className={`${styles.rjc} d-flex justify-content-between align-items-center flex-grow-1`}>
                                    <div>
                                        {user.userProfile?.rjcMember ?
                                            <>
                                                <img width={92} src={rjcLogo} />
                                                <p className="small mt-1">RJC Registered:</p>
                                                <h1 className="bold mb-0 uppercase">{user.userProfile?.rjcRegisteredStatusName}</h1>
                                            </> : <h2 className="mb-0 bold">Not registered</h2>}
                                    </div>
                                    <div>
                                        {user.userProfile?.rjcRegisteredStatusName?.length > 0 ? renderRjcImage() : <img width={92} src={rjcLogo} />}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex">
                                <div className={`${styles.dbs} d-flex justify-content-between align-items-center flex-grow-1`}>
                                    <div>
                                        {user.userProfile?.dbsCheck ?
                                            <>
                                                <p className="mb-1">{user.userProfile?.dbsCheckDate}</p>
                                                <p className="d-flex align-items-center">{user.userProfile?.dbsReference}
                                                    <CopyToClipboard text={user.userProfile?.dbsReference}
                                                        onCopy={() => setCopy(true)}
                                                    >
                                                        <button className="ml-1 d-flex align-items-center">
                                                            {copy ? <CheckCircle className="mr-1" /> : <Copy />}
                                                        </button>
                                                    </CopyToClipboard>
                                                </p>
                                            </>
                                            : <h2 className="mb-0 bold">DBS Status unknownn</h2>}
                                    </div>
                                    {user.userProfile?.dbsCheck ? <img width={68} src={dbsSet} /> : <img width={68} src={dbsUnknown} />}
                                </div>
                            </div>
                        </div>
                        <QualificationsExperience currentUser={currentUser} user={user} setShowMember={setShowMember} />
                    </>
                }
                {currentUser.isOrganizationAdmin &&
                    <EditMembership currentUser={currentUser} organization={organization} close={close} user={user} />}
            </div>
        </div>
    )

}

export default ViewMember;