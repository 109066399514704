import { gql } from 'apollo-boost';
import { referralQuery } from '../../../utils/constants';

export const GET_OFFICE_CASES = gql`
query office($archived: Boolean, $officeId: ID!) {
    office(officeId: $officeId) {
        id
        circles(archived: $archived) {
            id
            name
            caseNumber
            status
            mode
            archived
            caseType {
                id
                name
            }
            createdAt
            leader {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
}
`;

const circleMembership = `
id
role
requiresPersona
user {
    id
    firstName
    lastName
    photoUrl
    email
    requiresEmail
    uniqueMemberCode
    needsConfirmation
    userProfile {
        id
        address
        parentDetails
        healthIssues
        bame
        contactNumber
        otherRelevantInformation
        previousRecord
    }
}
`

export const GET_CASE = gql`
query circle($circleId: ID!) {
    circle(circleId: $circleId) {
        id
        name
        caseNumber
        caseDesignation
        createdAt
        mode
        archived
        sessionRequests {
            id
            approvedOrRejectedAt
        }
        office {
            id
        }
        caseType {
            id
            name
        }
        initiative {
            id
            name
        }
        referralSource {
            id
            name
        }
        other {
            id
            name
        }
        progress
        incidentDate
        incidentDetails
        reasonForReferral
        otherAgencyCommunityInvolvement
        hateCrime
        desiredOutcome
        conductCircleVirtually
        status
        setupStep
        statusLabel
        affectedParties {
            ${circleMembership}
            supportingUsers {
                ${circleMembership}
            }
        }
        leader {
            ${circleMembership}
        }
        secondLeader {
            ${circleMembership}
        }

        responsibleParties {
            ${circleMembership}
            supportingUsers {
                ${circleMembership}
            }
        }
        communityMembers {
            ${circleMembership}
        }
        participants {
            ${circleMembership}
        }
        finalAssessment {
            id
            affectedPartySatisfied
            justiceDone
            terminatedEarly
            responsibleShowRemorse
            responsibleRepaired
            success
        }
        currentAgreement {
            id
            attachmentUrl
            status
            approvals {
                id
                approved
                circleMembership {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
        agreements {
            id
            attachmentUrl
            status
            createdAt
            addedBy {
                id
                firstName
                lastName
            }
        }
        chats {
            id
            circlePhase
            closedAt
        }
        summary
        referral {
            ${referralQuery}
        }
    }   
}
`;


export const GET_OFFICE_LEADERS = gql`
query office($officeId: ID!) {
    office(officeId: $officeId) {
        id
        leaders {
            id
            firstName
            lastName
        }
    }
}
`;

export const CREATE_CIRCLE = gql`
mutation createCircle($name: String!, $officeId: ID!, $initiativeId: ID, $caseType: ID, $referralSourceId: ID!, $otherId: ID, $conductCircleVirtually: Boolean!, $mode: Int, $makeCurrentUserLeader: Boolean) {
    createCircle(name: $name, officeId: $officeId,  initiativeId: $initiativeId, caseType: $caseType, referralSourceId: $referralSourceId, otherId: $otherId, conductCircleVirtually: $conductCircleVirtually, mode: $mode, makeCurrentUserLeader: $makeCurrentUserLeader) {
        errors
        circle {
            id
            status
            setupStep
        }
    }
}
`;

export const ADD_CIRCLE_LEADERS = gql`
mutation addCircleLeaders($circleId: ID!, $leaderId: ID, $secondLeaderId: ID) {
    addCircleLeaders(circleId: $circleId, leaderId: $leaderId, secondLeaderId: $secondLeaderId) {
        errors
        circle {
            id
            leader {
                ${circleMembership}
            }
            secondLeader {
                ${circleMembership}
            }
        }
    }
}`;

export const EDIT_CIRCLE_LEADERS = gql`
mutation editCircleLeaders($circleId: ID!, $leaderId: ID, $secondLeaderId: ID) {
    editCircleLeaders(circleId: $circleId, leaderId: $leaderId, secondLeaderId: $secondLeaderId) {
        errors
        circle {
            id
            leader {
                ${circleMembership}
            }
            secondLeader {
                ${circleMembership}
            }
        }
    }
}`;

export const TOGGLE_PERSONA_REQUIREMENT = gql`
mutation togglePersonaRequirement($circleMembershipId: ID!, $requiresPersona: Boolean!) {
    togglePersonaRequirement(circleMembershipId: $circleMembershipId, requiresPersona: $requiresPersona) {
        errors
        circleMembership {
            ${circleMembership}
        }
    }
}
`;


export const DELETE_MEMBERSHIP = gql`
mutation deleteCircleMembershipAndSupportUsers($circleMembershipId: ID!) {
    deleteCircleMembershipAndSupportUsers(circleMembershipId: $circleMembershipId) {
        errors 
        deleted
    }
}
`;

export const GET_CIRCLE_CHATS = gql`
query circle($circleId: ID!) {
    circle(circleId: $circleId) {
        id
        openCircleDate
        openCircleTime
        openCircleLocation
        chats {
            id
            circlePhase
            closedAt
            startedAt
            messages {
                id
                body
                attachmentUrl
                authorRole
                postedAt
                rejectionReason
                status
                author {
                    id
                    firstName
                    lastName
                    photoUrl
                }
            }
        }
    }
}`;


export const GET_PRIVATE_CHATS = gql`
query circle($circleId: ID!) {
    circle(circleId: $circleId) {
        id
        privateChats {
            id
            members {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }
            messages {
                id
                attachmentUrl
                body
                postedAt
                authorRole
                author {
                    id
                    firstName
                    lastName
                    photoUrl
                }
            }
        }
    }
}
`;

export const TOGGLE_ARCHIVED_CIRCLE = gql`
mutation toggleArchiveCircle($circleId: ID!, $archive: Boolean!) {
    toggleArchiveCircle(circleId: $circleId, archive: $archive) {
        errors
        circle {
            id
            archived
        }
    }
}
`;

export const GET_CIRCLE_TIME_LOGS = gql`
query circle($circleId: ID!) {
    circle(circleId: $circleId) {
      id
      leader {
        id
        user {
            id
            firstName
            lastName
        }
      }
      secondLeader {
        id
        user {
            id
            firstName
            lastName
        }
      }
      logs {
        id
        description
        duration
        logDate
        user {
            id
            firstName
            lastName
        }
      }
        
    }
}
`;

export const ADD_CIRCLE_MEMBER = gql`
mutation addCircleMember($circleId: ID!, $role: Int!, $userData: UserInput!) {
    addCircleMember(circleId: $circleId, role: $role, userData: $userData) {
        errors
        circleMember {
            id
            role
            status
            supportingUsers {
                id
                role
                user {
                    id
                    firstName
                    lastName
                }
            }
            user {
                id
                firstName
                lastName
                userProfile {
                    id
                    address
                    bame
                    contactNumber
                    healthIssues
                    otherRelevantInformation
                    parentDetails
                    previousRecord

                }
            }
        }
    }
}
`;

export const EDIT_CIRCLE_MEMBER = gql`
mutation editCircleMember($circleId: ID!, $role: Int!, $userData: UserInput!) {
    editCircleMember(circleId: $circleId, role: $role, userData: $userData) {
        errors
        circleMember {
            id
            role
            status
            user {
                id
                firstName
                lastName
                userProfile {
                    id
                    address
                    bame
                    contactNumber
                    healthIssues
                    otherRelevantInformation
                    parentDetails
                    previousRecord

                }
            }
        }
    }
}
`;

export const GET_USER_PROFILE_ETHNICITIES = gql`
query userProfileGendersEthnicitiesAndCountries {
    userProfileGendersEthnicitiesAndCountries {
        ethnicities {
            id
            details {
                id
                name
            }
            group
        }
    }
}
`;

export const ADD_CIRCLE_DETAILS = gql`
mutation addCircleDetails($circleId: ID!, $reasonForReferral: String, $desiredOutcome: String, $hateCrime: Boolean, $otherAgencyCommunityInvolvement: String, $incidentDetails: String, $incidentDate: String, $isDraft: Boolean, $isFinal: Boolean) {
    addCircleDetails(circleId: $circleId, reasonForReferral: $reasonForReferral, desiredOutcome: $desiredOutcome, hateCrime: $hateCrime, otherAgencyCommunityInvolvement: $otherAgencyCommunityInvolvement, incidentDetails: $incidentDetails, incidentDate: $incidentDate, isDraft: $isDraft, isFinal: $isFinal) {
        errors
        circle {
            id
            status
            setupStep
            leader {
                ${circleMembership}
            }
            secondLeader {
                ${circleMembership}
            }
        }
    }
}
`;


export const EDIT_CASE = gql`
    mutation editCircle(
        $circleId: ID!,
        $name: String!,
        $initiativeId: ID,
        $caseTypeId: ID,
        $referralSourceId: ID!,
        $otherId: ID,
        $conductCircleVirtually: Boolean!
    ) {
    editCircle(
        circleId: $circleId,
        name: $name, 
        initiativeId: $initiativeId,
        caseTypeId: $caseTypeId,
        referralSourceId: $referralSourceId,
        otherId: $otherId,
        conductCircleVirtually: $conductCircleVirtually
    ) {
        errors
        circle {
            id
            name
        }
    }
}
`;

export const GET_CASE_NOTES = gql`
query circle($circleId: ID!) {
    circle(circleId: $circleId) {
        id
        status
        caseNotes {
            id
            text
            createdAt
            author {
                id
                firstName
                lastName
            }
            documents {
                id
                name
                url
            }
        }
    }
}
`;

// replaced by below
export const ADD_CASE_NOTE = gql`
mutation addCaseNote($circleId: ID!, $text: String!, $authorId: ID!, $documentUrl: String, $documentName: String) {
    addCaseNote(circleId: $circleId, text: $text, authorId: $authorId, documentUrl: $documentUrl, documentName: $documentName) {
        caseNote {
            id
        }
        errors
    }
}
`;

export const ADD_TIME_LOG_OR_CASE_NOTE = gql`
mutation addTimeLogOrCaseNote($circleId: ID!, $text: String, $authorId: ID, $documents: [DocumentInput!],  $duration: Float, $logDate: String) {
    addTimeLogOrCaseNote(circleId: $circleId, text: $text, authorId: $authorId, documents: $documents, duration: $duration, logDate: $logDate) {
        caseNote {
            id
        }
        log {
            id
            duration
            logDate
        }
        errors
    }
}
`;

export const EDIT_CASE_NOTE = gql`
mutation editCaseNote($caseNoteId: ID!, $text: String!,  $documentUrl: String, $documentName: String) {
    editCaseNote(caseNoteId: $caseNoteId, text: $text,  documentUrl: $documentUrl, documentName: $documentName) {
        caseNote {
            id
        }
        errors
    }
}
`;

export const DELETE_CASE_NOTE = gql`
mutation deleteCaseNote($caseNoteId: ID!) {
    deleteCaseNote(caseNoteId: $caseNoteId) {
        deleted
        errors
    }
}
`;

export const GET_USER_CIRCLES = gql`
query currentUser($archived: Boolean, $includeDrafts: Boolean) {
    currentUser {
        id
        firstName
        lastName
        email
        isOrganizationAdmin
        photoUrl
        officeMemberships {
            id
            isAdmin
            isCircleLeader
            office {
                id
                name
            }
        }
        cases(archived: $archived, includeDrafts: $includeDrafts) {
            id
            name
            mode
            archived
            office {
                id
                name
            }
            caseNumber
            status
            caseType {
                id
                name
            }
            createdAt
            leader {
                id
                user {
                    id
                    firstName
                    lastName
                }
            }        
        }
    }
}
`;

export const GET_CIRCLE_SESSIONS = gql`
query circle($circleId: ID!) {
    circle(circleId: $circleId) {
        id
        participants {
            ${circleMembership}
        }
        office {
            id
        }
        leader {
            id
            user {
                id
            }
        }
        secondLeader {
            id
            user {
                id
            }
        }
        sessionRequests {
            id
            createdAt
            additionalSessionsApproved
            approvedOrRejectedAt
            approvedOrRejectedBy {
                id
                firstName
                lastName
            }
            requestedBy {
                id
                firstName
                lastName
            }
        }
        sessions {
            id
            date
            time
            isOnline
            index
            mentoringSessionPlannedTheme {
                id
                name
            }
            status
            debrief {
                id
                status
            } 
        }
    }
}
`;


export const EDIT_SESSION_DEBRIEF = gql`
mutation editSessionDebrief($debriefId: ID!, $actualContentDescription: String, $actualMentoringSessionThemeId: ID, $challenges: String, $duration: Int, $healthAndSafety: Boolean, $healthAndSafetyDescription: String, $otherIssues: String, $positiveOutcomes: String, $raceEqualityDiversity: Boolean, $raceEqualityDiversityDescription: String, $safeguarding: Boolean, $safeguardingDescription: String) {
    editSessionDebrief(debriefId: $debriefId, actualContentDescription: $actualContentDescription, actualMentoringSessionThemeId: $actualMentoringSessionThemeId, challenges: $challenges, duration: $duration, healthAndSafety: $healthAndSafety,  healthAndSafetyDescription: $healthAndSafetyDescription, otherIssues: $otherIssues, positiveOutcomes: $positiveOutcomes, raceEqualityDiversity: $raceEqualityDiversity, raceEqualityDiversityDescription: $raceEqualityDiversityDescription, safeguarding: $safeguarding, safeguardingDescription: $safeguardingDescription) {
        session {
            id
            debrief {
                id
                status
                approvedOrRejectedAt
                approvedOrRejectedBy {
                    id
                    firstName
                    lastName
                }
                actualContentDescription
                actualMentoringSessionTheme {
                    id
                    name
                }
                files
                challenges
                duration
                healthAndSafety
                healthAndSafetyDescription
                otherIssues
                positiveOutcomes
                raceEqualityDiversity
                raceEqualityDiversityDescription
                safeguarding
                safeguardingDescription
            }   
        }
        errors
    }
}
`;


export const GET_SESSION = gql`
query session($sessionId: ID!) {
    session(sessionId: $sessionId) {
        id
        date
        time
        isOnline
        index
        meetingDescription
        meetingUrl
        expectedDuration
        mentoringSessionPlannedTheme {
            id
            name
        }
        mentoringSessionCategory {
            id
            name
        }
        mentoringCancellationReason {
            id
            name
        }
        status
        plannedContentDescription
        mentoringLocation {
            id
            name
            city
            address1
            address2
            address3
            zipCode
        }
        debrief {
            id
            status
            approvedOrRejectedAt
            approvedOrRejectedBy {
                id
                firstName
                lastName
            }
            actualContentDescription
            actualMentoringSessionTheme {
                id
                name
            }
            files
            challenges
            duration
            healthAndSafety
            healthAndSafetyDescription
            otherIssues
            positiveOutcomes
            raceEqualityDiversity
            raceEqualityDiversityDescription
            safeguarding
            safeguardingDescription
        } 
        contactChallenges
        contactMade
        coordinatorNotified
        mentoringCancellationReason {
            id
            name
        }
        otherReasonDescription        
    }
}
`;
export const TOGGLE_DEBRIEF_APPROVE = gql`
mutation approveSessionDebrief($debriefId: ID!, $status: Int!) {
    approveSessionDebrief(debriefId: $debriefId, status: $status ) {
        errors 
        debrief {
            id
            status
            approvedOrRejectedAt
            approvedOrRejectedBy {
                id
                firstName
                lastName
            }
            actualContentDescription
            actualMentoringSessionTheme {
                id
                name
            }
            files
            challenges
            duration
            healthAndSafety
            healthAndSafetyDescription
            otherIssues
            positiveOutcomes
            raceEqualityDiversity
            raceEqualityDiversityDescription
            safeguarding
            safeguardingDescription
        }
    }
}
`;

export const APPROVE_EXTENSION = gql`
mutation approveOrRejectAdditionalSessions($sessionRequestId: ID!, $approved: Boolean!) {
    approveOrRejectAdditionalSessions(sessionRequestId: $sessionRequestId, approved: $approved) {
        errors
        circle {
            id
        }
        sessionRequest {
            id
        }
    }
}
`;