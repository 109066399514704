import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_REPORTS_COUNTERS } from "../services/graphql";


const Sidebar = ({ tab, TABS, setTab, officeId }) => {

    const { data } = useQuery(GET_REPORTS_COUNTERS, {
        variables: {
            officeId
        }
    })

    if (data?.mentoringSessionsReports) {
        const reportStats = data.mentoringSessionsReports;
        return (
            <>
                <ul className="list-group">
                    <li className={`list-item  ${tab === TABS.overdue ? 'list-item--separator' : 'list-item--separator-dark'}`}>
                        <button onClick={() => setTab(TABS.overdue)} className="width-full d-flex justify-content-between">
                            <span>Overdue</span>
                            {reportStats.find(el => el.status === 'Overdue')?.count > 0 && <span className="label label-primary">{reportStats.find(el => el.status === 'Overdue')?.count}</span>}
                        </button>
                    </li>
                    <li className={`list-item  ${tab === TABS.review ? 'list-item--separator' : 'list-item--separator-dark'}`}>
                        <button onClick={() => setTab(TABS.review)} className="width-full d-flex justify-content-between">
                            <span>Requires review</span>
                            {reportStats.find(el => el.status === 'Requires Review')?.count > 0 && <span className="label label-primary">{reportStats.find(el => el.status === 'Requires Review')?.count}</span>}
                        </button>
                    </li>

                    <li className={`list-item  ${tab === TABS.due ? 'list-item--separator' : 'list-item--separator-dark'}`}>
                        <button onClick={() => setTab(TABS.due)} className="width-full d-flex justify-content-between">
                            <span>Due</span>
                            {reportStats.find(el => el.status === 'Due')?.count > 0 && <span className="label label-primary">{reportStats.find(el => el.status === 'Due')?.count}</span>}
                        </button>
                    </li>

                    <li className={`list-item  ${tab === TABS.soon ? 'list-item--separator' : 'list-item--separator-dark'}`}>
                        <button onClick={() => setTab(TABS.soon)} className="width-full d-flex justify-content-between">
                            <span>Schedulled soon</span>
                        </button>
                    </li>

                    <li className={`list-item  ${tab === TABS.custom ? 'list-item--separator' : 'list-item--separator-dark'}`}>
                        <button onClick={() => setTab(TABS.custom)} className="width-full d-flex justify-content-between">
                            <span>Custom</span>
                        </button>
                    </li>
                    <li></li>
                </ul>
            </>
        )
    }
    return null;
}

export default Sidebar;