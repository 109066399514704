import React, { useState } from "react";
import styles from '../../ViewCase.module.scss'
import CaseNotes from "./CaseNotes";
import CircleChats from "./CircleChats";
import TimeLogs from "./TimeLogs";
import Outcomes from "./Outcomes";
import AgreementRepair from "./AgreementRepair";
import Sessions from "./Sessions";

import DirectChats from "./DirectChats";
import { CIRCLE_MODE } from "../../../../../../utils/constants";

const TABS = {
    notes: 1,
    transcript: 2,
    agreement: 3,
    outcomes: 4,
    chats: 5,
    logs: 6,
    sessions: 7
}

const NotesAndDocs = ({ circle }) => {

    const [tab, setTab] = useState(TABS.notes)

    return (
        <>
            <div className={styles.notesDocs}>
                <div className={styles.notesNav}>
                    <ul>
                        <li>
                            <button className={`sideNavLink ${tab === TABS.notes ? 'sideNavLinkActive' : ''}`} onClick={() => setTab(TABS.notes)}>Case Notes</button>
                        </li>
                        {circle.conductCircleVirtually &&
                            <li>
                                <button className={`sideNavLink ${tab === TABS.transcript ? 'sideNavLinkActive' : ''}`} onClick={() => setTab(TABS.transcript)}>Circle Transcripts</button>
                            </li>
                        }

                        {circle.mode === CIRCLE_MODE.mentoring &&
                            <li>
                                <button className={`sideNavLink ${tab === TABS.sessions ? 'sideNavLinkActive' : ''}`} onClick={() => setTab(TABS.sessions)}>Sessions</button>

                            </li>
                        }
                        {circle.mode !== CIRCLE_MODE.mentoring &&

                            <li>
                                <button
                                    className={`sideNavLink ${tab === TABS.agreement ? 'sideNavLinkActive' : ''}`}
                                    onClick={() => setTab(TABS.agreement)}
                                >Agreement & Summary</button>
                            </li>
                        }
                        <li>
                            <button
                                className={`sideNavLink ${tab === TABS.outcomes ? 'sideNavLinkActive' : ''}`}
                                onClick={() => setTab(TABS.outcomes)}
                            >Outcomes</button>
                        </li>
                        <li>
                            <button
                                className={`sideNavLink ${tab === TABS.chats ? 'sideNavLinkActive' : ''}`}
                                onClick={() => setTab(TABS.chats)}
                            >Direct Chats</button>
                        </li>

                        <li>
                            <button
                                className={`sideNavLink ${tab === TABS.logs ? 'sideNavLinkActive' : ''}`}
                                onClick={() => setTab(TABS.logs)}
                            >Time Logs</button>
                        </li>

                    </ul>
                </div>
                <div>
                    {tab === TABS.notes && <CaseNotes circle={circle} />}
                    {tab === TABS.transcript && <CircleChats circleId={circle.id} />}
                    {tab === TABS.agreement && <AgreementRepair circle={circle} />}

                    {tab === TABS.outcomes && <Outcomes circle={circle} />}
                    {tab === TABS.chats && <DirectChats circleObj={circle} />}
                    {tab === TABS.logs && <TimeLogs circleId={circle.id} />}

                    {tab === TABS.sessions && <Sessions circleData={circle} circleId={circle.id} />}

                </div>
            </div>
        </>
    )
}

export default NotesAndDocs;