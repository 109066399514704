import React, { useEffect, useState } from "react";
import styles from './CaseReports.module.scss';
import SessionItem from "./components/SessionItem";
import moment from "moment";
import CustomFilters from "./components/CustomFilters";
import SoonFilters from "./components/SoonFilters";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_SESSION_REPORTS } from "./services/graphql";
import Sidebar from "./components/SideBar";

const TABS = {
    overdue: 1,
    review: 2,
    due: 3,
    soon: 4,
    custom: 5
}


const CaseReports = ({ officeId }) => {

    const now = moment();

    const [reports, setReports] = useState([]);
    const [tab, setTab] = useState(TABS.overdue);


    const getFilterByTab = () => {
        switch (tab) {
            case TABS.overdue:
                return 'Overdue';
            case TABS.review:
                return 'Requires Review';
            case TABS.due:
                return 'Due';
            case TABS.soon:
                return 'Scheduled Soon';
        }
    }

    const [getSessionReports, { loading }] = useLazyQuery(GET_SESSION_REPORTS, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data?.mentoringSessionsReports) {
                if (tab !== TABS.custom) {
                    const currentReports = data?.mentoringSessionsReports.filter(report => report.status === getFilterByTab(tab))
                    setReports(currentReports[0]?.sessions)
                }
                else {
                    const allSessions = [];
                    data.mentoringSessionsReports.forEach(el => {
                        if (el.sessions.length > 0) {
                            allSessions.push(...el.sessions);
                        }
                    });
                    setReports(allSessions);
                }
            }
        }
    });

    useEffect(() => {
        if (tab !== TABS.custom) {
            getSessionReports({
                variables: {
                    officeId,
                    statusFilters: [getFilterByTab(tab)]
                }
            })
        } else {
            setReports([]);
        }
    }, [tab, setReports, getSessionReports])


    const onApplyCustomFilters = (customFilters, dateRange) => {
        let dates = {};
        if (customFilters.date.indexOf('last') > -1) {
            const val = customFilters.date.split('_')[1];
            if (val === 'month') {
                dates.from = moment().subtract(1, 'month').startOf('month').format('DD/MM/YYYY')
                dates.to = moment().subtract(1, 'month').endOf('month').format('DD/MM/YYYY')
            } else {
                dates.from = now.clone().subtract(val, 'days').format('DD/MM/YYYY');
                dates.to = moment().format('DD/MM/YYYY')
            }
        } else if (customFilters.date.indexOf('next') > -1) {
            dates.from = now.format('DD/MM/YYYY');
            dates.to = now.clone().add(14, 'days').format('DD/MM/YYYY')
        } else if (customFilters.date?.indexOf('custom') > -1 && dateRange.startDate && dateRange.endDate) {
            dates.from = dateRange.startDate
            dates.to = dateRange.endDate
        }

        getSessionReports({
            variables: {
                officeId,
                ...(dates && {
                    from: dates.from,
                    to: dates.to
                }),
                ...(customFilters.status && { statusFilters: customFilters.status }),
                ...(customFilters.mentor && { mentorId: customFilters.mentor }),
            }
        });

    }


    const onApplySoonFilters = dates => {
        getSessionReports({
            variables: {
                officeId,
                statusFilters: [getFilterByTab(tab)],
                ...(dates && {
                    from: dates.from,
                    to: dates.to
                })
            }
        });
    }

    return (
        <>
            <h1 className="mb-3">Case Reports</h1>
            <div className={styles.reports}>
                <div className={styles.reportsSide}>
                    <Sidebar officeId={officeId} tab={tab} setTab={setTab} TABS={TABS} />
                </div>
                <div>
                    {tab === TABS.soon &&
                        <SoonFilters onApplySoonFilters={onApplySoonFilters} />
                    }

                    {tab === TABS.custom &&
                        <CustomFilters officeId={officeId} onApplyCustomFilters={onApplyCustomFilters} />
                    }
                    <ul className="list-group">
                        <li className={`list-item bold ${styles.session}`}>
                            <div className={styles.sessionName}>
                                Case & Session number
                            </div>
                            <div className={styles.sessionCircle}>
                                Lead Mentor
                            </div>
                            <div className={styles.sessionDate}>
                                Session
                            </div>
                            <div className={`${styles.sessionStatus} text-center`}>
                                Report
                            </div>
                        </li>
                        {loading ? <p>Loading...</p> :
                            <>

                                {reports?.length > 0 ? <>
                                    {reports.map(session =>
                                        <SessionItem reportsOfficeId={officeId} setReports={setReports} reports={reports} key={session.id} session={session} />
                                    )}
                                </> : <p>No reports</p>}
                            </>
                        }
                    </ul>
                </div>
            </div>
        </>
    )


}

export default CaseReports;