import React, { useState } from "react";
import { ASSIGN_REFERRAL_TO_OFFICE, GET_ORG_REFERRALS, GET_OFFICE_REFERRALS } from "../services/graphql";
import { useMutation } from "@apollo/react-hooks";
import FormErrors from '../../../components/FormErrors';

const AssignToOffice = ({ referral, close, organization, setShowAssign, currentUser, setCurrentUser }) => {

    const [mutationErrors, setMutationErrors] = useState([]);
    const [selectedOffice, setSelectedOffice] = useState('');


    const referralQueries = () => {
        if (referral.office?.id) {
            return [
                { query: GET_ORG_REFERRALS,  variables: { organizationId: organization.id},  },
                { query: GET_OFFICE_REFERRALS, variables: { officeId: referral.office.id } },
            ]
        }
        return [{ query: GET_ORG_REFERRALS, variables: { organizationId: organization.id } }]
    }

    const [assignReferralToOffice, { loading }] = useMutation(ASSIGN_REFERRAL_TO_OFFICE, {
        update: (_, { data }) => {
            if (data?.assignReferralToOffice?.errors?.length > 0) {
                setMutationErrors(data.assignReferralToOffice.errors);
                return;
            }
            if (data.assignReferralToOffice.referral?.id) {
                const updatedReferrals = [];
                currentUser.organization.referrals.forEach(r => {
                    if (r.id === referral.id) {
                        updatedReferrals.push({
                            ...r, 
                            status: data.assignReferralToOffice.referral.status,
                            office: data.assignReferralToOffice.referral.office
                        })
                    } else {
                        updatedReferrals.push(r);
                    }
                });
                const newCu = { ...currentUser, organization: { ...currentUser.organization, referrals: [...updatedReferrals] } }
                setCurrentUser(newCu);

                setShowAssign(false);
                close();
            }
        },
        refetchQueries: () => referralQueries()
    });

    return (

        <div className="bg-primary-light radius-3 p-2  mb-2">
            <div className="row">
                <div className="col-md-8 d-flex align-items-center">
                    <span className="mr-1 lead">Assign to:</span>
                    <select
                        value={selectedOffice}
                        onChange={(e) => setSelectedOffice(e.target.value)}
                        className="basic-form__text-select mb-0 mr-1"
                        style={{ width: 'auto' }}
                    >
                        <option value=''>Please select</option>
                        {currentUser.organization.offices.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
                    </select>
                    <button disabled={loading} onClick={() => assignReferralToOffice({
                        variables: {
                            officeId: selectedOffice,
                            referralId: referral.id
                        }
                    })} className="btn btn-primary mr-1">Assign</button>
                </div>
                <div className="col-md-4 d-flex align-items-center">
                    <button onClick={() => setShowAssign(false)} className="btn btn-outline ml-auto">Cancel</button>
                </div>
            </div>
            <FormErrors errors={mutationErrors} />
        </div>
    )
}


export default AssignToOffice;