import React, { useState, useContext } from "react";
import ReferralHeadOffice from "./components/ReferralHeaderOffice";
import { AlertCircle, ArrowLeft, Calendar, Download, Eye, FileText, XCircle } from "react-feather";
import { AuthContext } from "../../services/auth/AuthProvider";
import { Referrer, Agencies, Family, Yp, Risks, Mentor } from "./components/tabsContent";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "@reach/router";
import { REFERRAL_STATUS } from "../../utils/constants";
import DeclineReferral from "./components/DeclineReferral";
import AssignToOffice from "./components/AssignToOffice";
import { GET_REFERRAL } from "./services/graphql";
import Modal from "../../components/Modal";
import axios from "axios";
import moment from "moment";
import styles from './Referrals.module.scss'

const TABS = {
    referrer: 1,
    yp: 2,
    family: 3,
    agencies: 4,
    risks: 5,
    mentor: 6

}

const ViewReferral = ({ referralItem, close = null, organization, officeId }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [tab, setTab] = useState(TABS.referrer);
    const [showAssign, setShowAssign] = useState(false);
    const [showDecline, setShowDecline] = useState(false);

    const [confirmDown, setConfirmDown] = useState(false);
    const [agree, setAgree] = useState(false);

    const { data, loading, error } = useQuery(GET_REFERRAL, {
        variables: {
            referralId: referralItem?.id
        }
    });


    const renderTabContent = (t, referral) => {
        switch (t) {
            case TABS.referrer:
                return <Referrer referral={referral} />
            case TABS.yp:
                return <Yp referral={referral} />
            case TABS.family:
                return <Family referral={referral} />
            case TABS.agencies:
                return <Agencies referral={referral} />
            case TABS.risks:
                return <Risks referral={referral} />
            case TABS.mentor:
                return <Mentor referral={referral} />
            default:
                return null;
        }
    }

    const onDownloadFile = (url) => {
        axios({
            method: 'get',
            url: url,
            responseType: 'blob'
        }).then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = downloadUrl;

            // Extract the filename from the Content-Disposition header or use a default name
            const contentDisposition = response.headers['content-disposition'];
            let filename = url.split('/').pop();

            if (contentDisposition) {
                const matches = /filename="?(.+)"?/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1];
                }
            }

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
            setConfirmDown(false);

        }).catch((error) => {
            console.error('Failed to download file:', error);
        });
    }

    if (loading) {
        return <p>Loading...</p>
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.referral?.id) {

        const { referral } = data;
        return (
            <div className={`${close && 'card'} ${!officeId && 'card-blue'}`}>
                <div className={`${close && 'card-body'}`}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className="d-flex align-items-center">
                            {close && <button onClick={close}><ArrowLeft className="mr-1" /> </button>} {referral.youngPersonFirstName} {referral.youngPersonLastName}
                        </h2>
                        {close && <button onClick={close}><XCircle className="mr-1" /></button>}
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <p className="d-flex align-items-center"><Calendar className="mr-1 text-primary" /> Requested on: {moment(referral.referralDate).format('DD MMM YYYY')}</p>
                        {referral.circle &&
                            <Link className="btn btn-outline d-flex align-items-center" to={`${officeId ? `/offices/${officeId}/cases` : '/cases'}/${referral.circle.id}`}><FileText className="mr-1" />View case</Link>
                        }
                    </div>
                    {referral.status === REFERRAL_STATUS.rejected &&
                        <p>Reject: {referral.rejectedReason}</p>}

                    {!showAssign && !showDecline && referral.status === REFERRAL_STATUS.new &&
                        <div className="d-flex mb-2">
                            <button className="btn btn-primary mr-2" onClick={() => setShowAssign(true)}>Assign</button>
                            <button className="btn btn-outline" onClick={() => setShowDecline(true)}>Decline</button>
                        </div>
                    }
                    {referral.office && !referral.circle && !showDecline &&
                        <ReferralHeadOffice
                            setShowDecline={setShowDecline}
                            referral={referral}
                            organization={currentUser.organization}
                            close={close}
                            currentUser={currentUser}
                            setCurrentUser={setCurrentUser}
                        />
                    }

                    {
                        showAssign ?
                            <AssignToOffice
                                referral={referral}
                                close={close}
                                organization={organization}
                                currentUser={currentUser}
                                setCurrentUser={setCurrentUser}
                                setShowAssign={setShowAssign}
                            /> : showDecline &&
                            <DeclineReferral
                                referral={referral}
                                close={close}
                                organization={organization}
                                setShowDecline={setShowDecline}
                                currentUser={currentUser}
                                setCurrentUser={setCurrentUser}
                            />
                    }

                    <hr className="separator" />
                    {referral.fileUrl ? <>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <a className="btn btn-primary d-flex align-items-center" target="blank" href={referral.fileUrl}><Eye className="mr-1" /> Open/View Referral file</a>
                                </div>
                                <button className="btn btn-outline mb-3 d-flex align-items-center width-full" onClick={() => setConfirmDown(true)}><Download className="mr-1" />Download Referral File</button>
                                <p className="uppercase bold small text-gray-2 mb-1">Added by:</p>
                                {referral.addedBy && <p>{referral.addedBy?.firstName} {referral.addedBy?.lastName}</p>}
                                <p className="d-flex align-items-center"> <Calendar className="mr-1 text-primary" />{moment(referral.createdAt).format('DD MMM YYYY')} @{moment(referral.createdAt).format('HH:mm')} </p>
                            </div>
                        </div>
                    </> :
                        <div className={`${styles.refDetails} mt-3`}>
                            <ul>
                                <li>
                                    <button className={`sideNavLink ${tab === TABS.referrer ? 'sideNavLinkActive' : ''}`} onClick={() => setTab(TABS.referrer)}>Referrer</button>
                                </li>
                                <li>
                                    <button className={`sideNavLink ${tab === TABS.yp ? 'sideNavLinkActive' : ''}`} onClick={() => setTab(TABS.yp)}>Young Person</button>
                                </li>
                                <li>
                                    <button className={`sideNavLink ${tab === TABS.family ? 'sideNavLinkActive' : ''}`} onClick={() => setTab(TABS.family)}>Family and structure</button>
                                </li>
                                <li>
                                    <button className={`sideNavLink ${tab === TABS.agencies ? 'sideNavLinkActive' : ''}`} onClick={() => setTab(TABS.agencies)}>Agencies</button>
                                </li>

                                <li>
                                    <button className={`sideNavLink ${tab === TABS.risks ? 'sideNavLinkActive' : ''}`} onClick={() => setTab(TABS.risks)}>Risks and health </button>
                                </li>
                                <li>
                                    <button className={`sideNavLink ${tab === TABS.mentor ? 'sideNavLinkActive' : ''}`} onClick={() => setTab(TABS.mentor)}>Mentor requirements</button>
                                </li>
                            </ul>
                            <div>
                                {renderTabContent(tab, referral)}
                            </div>
                        </div>
                    }
                </div>
                {confirmDown &&
                    <Modal closeModal={() => setConfirmDown(false)}>
                        <h3 className="d-flex align-items-center mb-3"><AlertCircle className="mr-1 text-danger" /> Sensitive data</h3>
                        <p>This referral form may conatin sensitive data. Please store securely and delete the file when it is no longer needed.</p>
                        <p>Do not share or forward the file outside of this app.</p>
                        <div className="d-flex align-items-center mb-3 mt-3">
                            <input name="agree" id='agree' className="basic-check-box mr-2" type="checkbox" value={agree} onChange={(e) => setAgree(e.target.checked)} />
                            <label htmlFor="agree" className="lead">I agree to keep this file secure</label>
                        </div>
                        <div className="d-flex align-items-center">
                            <button className="d-flex align-items-center btn btn-primary" disabled={!agree} onClick={() => onDownloadFile(referral.fileUrl)}><Download className="mr-1" /> Download</button>
                            <button className="btn btn-outline ml-2" onClick={() => setConfirmDown(false)}>Cancel</button>
                        </div>
                    </Modal>
                }
            </div>
        )
    }
    return null;
}

export default ViewReferral;