import React, { useState, useContext } from "react";
import Tab from "../../../../../components/Tab";
import Tabs from "../../../../../components/Tabs";
import { AGREEMENT_STATUS, CIRCLE_MODE, CIRCLE_STATUS } from "../../../../../utils/constants";
import { AlertCircle, Settings } from "react-feather";
import ProgressInfo from './ProgressInfo';
import { Check, X } from 'react-feather';
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from '../ViewCase.module.scss'
import Modal from "../../../../../components/Modal";
import listIcon from '../../../../../images/icons/list.svg';
import handsIcon from '../../../../../images/icons/hands.svg';
import { AuthContext } from "../../../../../services/auth/AuthProvider";

const CaseHeader = ({ circle, tab, setTab, setShowEdit, tabs }) => {

    const { currentUser } = useContext(AuthContext);

    const progress = circle.progress * 100;

    const isCurrentAgreementApproved = (currAgreement) => {
        if (!currAgreement) {
            return false;
        }
        if (currAgreement.status === AGREEMENT_STATUS.accepted) {
            return true
        }
        return false;
    }
    const isOfficeAdmin = currentUser?.officeMemberships.some(om => om.office?.id === circle.office?.id && om.isAdmin);
    const canApproveSessionRequest = isOfficeAdmin || currentUser?.isOrganizationAdmin;

    const [showProgress, setShowProgress] = useState(false);

    const participantsConfirmed = circle.participants.filter(p => p.user?.needsConfirmation)?.length ===0;
    const disabledDetails = !circle.leader || !participantsConfirmed || (circle.responsibleParties?.length === 0 && circle.affectedParties.length === 0 && circle.communityMembers?.length === 0 && circle.participants.length === 0);


    return (
        <>
            <div className="card mb-2">
                <div className="card-body d-flex align-items-center">
                    <div>
                        <div className="d-flex align-items-center">
                            <h3>{circle.name} <span className="ml-1 small">{circle.caseNumber}</span></h3>
                            <button type="button" onClick={() => setShowEdit(true)} className="ml-2"><Settings /></button>
                        </div>
                        <Tabs>
                            <Tab onClick={() => setTab(tabs.leaders)} selected={tab === tabs.leaders}>{circle.mode === CIRCLE_MODE.mentoring ? 'Mentors' : 'Leaders'}</Tab>
                            <Tab disabled={!circle.leader} onClick={() => setTab(tabs.members)} selected={tab === tabs.members}>{circle.mode === CIRCLE_MODE.mentoring ? 'Mentee' : 'Members'}</Tab>
                            <Tab disabled={disabledDetails} onClick={() => setTab(tabs.details)} selected={tab === tabs.details}>Details</Tab>
                            {circle.status !== CIRCLE_STATUS.draft && <Tab onClick={() => setTab(4)} selected={tab === 4}>Notes &amp; Docs</Tab>}
                            {circle.referral && <Tab icon="Info" iconSize={18} onClick={() => setTab(tabs.referral)} selected={tab === tabs.referral}> Referral</Tab>}

                        </Tabs>
                    </div>

                    <div className="ml-auto d-flex">
                        {circle.status !== CIRCLE_STATUS.draft &&
                            <>
                                <Tooltip size="big" title="Outcomes">
                                    <div className={`${styles.statusIcon} ${circle.finalAssessment ? styles.statusIconActive : ''} mr-2`}>
                                        <img src={listIcon} alt='Outcomes icon' />
                                        {circle.finalAssessment &&
                                            <span className={`${styles.statusIconReached} ${circle.finalAssessment.justiceDone ? '' : styles.statusIconReachedNot}`}>
                                                {circle.finalAssessment.justiceDone ? <Check /> : <X />}
                                            </span>
                                        }
                                    </div>
                                </Tooltip>
                                {circle.mode !== CIRCLE_MODE.mentoring && (
                                    <Tooltip size="big" title="Agreement">
                                        <div className={`${styles.statusIcon} ${isCurrentAgreementApproved(circle.currentAgreement) || (circle.status > CIRCLE_STATUS.agreement_repair) ? styles.statusIconActive : ''} mr-2`}>

                                            <img src={handsIcon} alt='Agreement icon' />
                                            {(circle.status > CIRCLE_STATUS.agreement_repair && !circle.currentAgreement) || (circle.status >= CIRCLE_STATUS.closed_pending_assessment && circle.currentAgreement?.status !== AGREEMENT_STATUS.accepted) ?
                                                <span className={`${styles.statusIconReached} ${styles.statusIconReachedNot}`}>
                                                    <X />
                                                </span> :
                                                circle.currentAgreement?.status === AGREEMENT_STATUS.accepted &&
                                                <span className={`${styles.statusIconReached}`}>
                                                    <Check />
                                                </span>
                                            }
                                        </div>
                                    </Tooltip>
                                )}
                            </>
                        }
                        <Tooltip size="big" title={circle.status === CIRCLE_STATUS.draft ? 'Draft Circle' : 'View Progress'}>
                            <button onClick={() => setShowProgress(true)} className='link d-flex align-items-center'>
                                <div className={styles.progressHolder}>
                                    <CircularProgressbarWithChildren
                                        value={progress}
                                        background
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            pathTransitionDuration: 0.5,
                                            pathColor: `rgba(227, 105, 77, ${progress * 100 / 100})`,
                                            trailColor: circle.status === CIRCLE_STATUS.draft ? '#B4B4B4' : '#F3CCBD',
                                            backgroundColor: circle.status === CIRCLE_STATUS.draft ? '#fff' : '#F3E4DE',
                                        })}
                                    >
                                        <div style={{ fontSize: 12, fontWeight: 'bold' }}>
                                            {canApproveSessionRequest && circle.sessionRequests?.filter(r => !r.approvedOrRejectedAt).length > 0 ?
                                                <AlertCircle className="mt-1" size={20} />
                                                :
                                                <>{circle.status === CIRCLE_STATUS.draft ? '-' : `${progress}%`}</>
                                            }
                                        </div>
                                    </CircularProgressbarWithChildren>
                                </div>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            {showProgress && (
                <Modal closeModal={() => setShowProgress(false)}>
                    <ProgressInfo canApproveSessionRequest={canApproveSessionRequest} circle={circle} />
                </Modal>
            )}
        </>
    )
}

export default CaseHeader;