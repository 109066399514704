import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import styles from '../CaseReports.module.scss';

const ndFilters = {
    next7: 7,
    next14: 14,
    next30: 30,
}

const SoonFilters = ({ onApplySoonFilters }) => {

    const now = moment();
    const ref = useRef(null);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);


    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowFilter(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    const selectedFilterTitle = selectedFilter > 0 ? `Next ${selectedFilter} days` : 'All scheduled'
    
    const onDone = () => {
        const dates = {
            from: now.format('DD/MM/YYYY'),
            to: now.clone().add(selectedFilter, 'days').format('DD/MM/YYYY')
        }
        onApplySoonFilters(selectedFilter ? dates : null);
    }

    return (
        <>
            <div className="mb-2">
                <div className="d-flex align-items-center justify-content-between">
                    <div ref={ref} className="relative d-block">
                        <button onClick={() => setShowFilter(!showFilter)} className="basic-form__text-select width-auto">
                            {selectedFilter ? selectedFilterTitle : 'All scheduled'}
                        </button>
                        {showFilter && (
                            <div className={styles.filterMenu}>
                                <ul>
                                    <li className="mb-2"><button className="d-flex align-items-center" onClick={() => { setSelectedFilter(ndFilters.next7); setShowFilter(false); }}><span className={`${styles.radioSelect} ${selectedFilter === ndFilters.next7 ? styles.radioSelectActive : ''}`}></span>Next 7 days</button></li>
                                    <li className="mb-2"><button className="d-flex align-items-center" onClick={() => { setSelectedFilter(ndFilters.next14); setShowFilter(false) }}><span className={`${styles.radioSelect} ${selectedFilter === ndFilters.next14 ? styles.radioSelectActive : ''}`}></span>Next 14 days</button></li>
                                    <li className="mb-2"><button className="d-flex align-items-center" onClick={() => { setSelectedFilter(ndFilters.next30); setShowFilter(false) }}><span className={`${styles.radioSelect} ${selectedFilter === ndFilters.next30 ? styles.radioSelectActive : ''}`}></span>Next 30 days</button></li>
                                    <li className="mb-2"><button className="d-flex align-items-center" onClick={() => { setSelectedFilter(ndFilters.all); setShowFilter(false) }}><span className={`${styles.radioSelect} ${!selectedFilter ? styles.radioSelectActive : ''}`}></span>All scheduled</button></li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <button className="btn btn-primary" onClick={() => onDone()}>Apply</button>
                </div>
            </div>
        </>
    )
}

export default SoonFilters;