import React, { useState, useRef } from "react";
import ls from 'local-storage';
import { ChevronDown } from "react-feather";
import { Link } from "@reach/router";
import { REFERRAL_STATUS } from "../../utils/constants";
import styles from './Sidebar.module.scss';
import styles2 from '../Dropdown/Dropdown.module.scss';

const OfficeAdminMenu = ({ offices, currentOrgAdminOffice, currentUser }) => {

    const [showMenu, setShowMenu] = useState(false);

    const menuRef = useRef(null);

    const officeContext = ls('office_context') && JSON.parse(ls('office_context'));

    const handleOfficeChange = (office) => {
        setShowMenu(false);
        if (!currentUser.isOrganizationAdmin) {
            ls('office_context', JSON.stringify(office));
        }
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: `${styles.sidebarMainNavLink} ${styles.sidebarMainNavLinkActive}` } : { className: styles.sidebarMainNavLink }
    }

    const isActive = ({ isCurrent }) => {
        return isCurrent ? { className: `${styles.sidebarMainNavLink} ${styles.sidebarMainNavLinkActive}` } : { className: styles.sidebarMainNavLink }
    }


    // { console.log('currentOrgAdminOffice', currentOrgAdminOffice)}
    const getSelectedOffice = () => {
        if (offices?.length) {
            let selectedOffice;
            if (currentOrgAdminOffice) {
                selectedOffice = currentOrgAdminOffice
            } else if (officeContext && offices.find(o => o.id === officeContext.id)) {
                selectedOffice = officeContext;
            }
            else {
                selectedOffice = offices[0]
            }
            return selectedOffice;
        }
    };

    const newOfficeReferrals = currentUser.organization.referrals.filter(r => r.status === REFERRAL_STATUS.has_office && r?.office?.id === getSelectedOffice()?.id).length;


    return (

        <>
            {/* {console.log('getSelectedOffice', getSelectedOffice())} */}

            {getSelectedOffice() &&
                <>
                    <div ref={menuRef} className={`${styles2.dropdown} ${'width-full mb-2'}`}>
                        <button disabled={offices.length === 1} className={`${'d-flex justify-content-between bold width-full'}`} onClick={() => setShowMenu(!showMenu)}>
                            {getSelectedOffice().name}
                            {offices.length > 1 && <ChevronDown />}
                        </button>
                        {showMenu &&
                            <ul className={`${styles2.dropdownMenu} ${styles2.dropdownMenuExpand} ${'width-full'}`}>
                                {offices.map(office =>
                                    <li key={`link-${office.id}`}>
                                        {currentUser.isOrganizationAdmin ?

                                            <Link onClick={() => handleOfficeChange(office)} to={`/offices/${office.id}`}>{office.name}</Link> :
                                            <Link onClick={() => handleOfficeChange(office)} to={`/`}>{office.name}</Link>
                                        }
                                    </li>
                                )}
                            </ul>
                        }
                    </div>
                    <ul className={styles.nestedOffices}>
                        {currentUser.isOrganizationAdmin ?
                            <li><Link to={`/offices/${getSelectedOffice().id}`} getProps={isActive}>{getSelectedOffice.id} Reports</Link></li> :
                            <li><Link to={`/`} getProps={isActive}>Reports</Link></li>
                        }
                    <li><Link to={`/offices/${getSelectedOffice().id}/caseReports`} getProps={isPartiallyActive}>Case Reports</Link></li>

                        <li><Link to={`/offices/${getSelectedOffice().id}/cases`} getProps={isPartiallyActive}>Cases</Link></li>
                        {currentUser.organization?.mentoringEnabled &&

                            <li>
                                <Link to={`/offices/${getSelectedOffice().id}/referrals`} getProps={isPartiallyActive}>
                                    <span className="d-flex align-items-center justify-content-between">
                                        Referrals
                                        {newOfficeReferrals > 0 && <span className="label label-primary">{newOfficeReferrals}</span>}
                                    </span>
                                </Link>
                            </li>
                        }
                        <li className="mb-3"><Link to={`/offices/${getSelectedOffice().id}/team`} getProps={isPartiallyActive}>Team</Link></li>
                    </ul>
                </>
            }
        </>
    )
}

export default OfficeAdminMenu;