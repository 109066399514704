import React from "react";
import { CheckCircle, Slash } from "react-feather";
import moment from "moment";

export const Referrer = ({ referral }) => {
    return (
        <>
            <div className="mb-2">
                <p className="small">Referred by:</p>
                <p>{referral.referrerFirstName} {referral.referrerLastName}</p>
            </div>

            <div className="mb-1">
                <p className="small">Email:</p>
                <p>{referral.referrerEmail || '-'}</p>
            </div>

            <div className="mb-1">
                <p className="small">Phone number:</p>
                <p>{referral.referrerPhoneNumber || '-'}</p>
            </div>

            <div className="mb-1">
                <p className="small">Company:</p>
                <p>{referral.referrerCompany || '-'}</p>
            </div>

            <div className="mb-1">
                <p className="small">Service/department:</p>
                <p>{referral.referrerDepartment || '-'}</p>
            </div>
        </>
    )
}

export const Yp = ({ referral }) => {

    const noYpAddress = !referral.typeformData?.youngPersonAddress && !referral.typeformData?.youngPersonCity && !referral.typeformData?.youngPersonCountry && !referral.typeformData?.youngPersonZipcode;

    const addressParts = [
        referral.typeformData?.youngPersonAddress,
        referral.typeformData?.youngPersonCity,
        referral.typeformData?.youngPersonCountry,
        referral.typeformData?.youngPersonZipcode,
    ].filter(Boolean);

    return (
        <>
            <div className="mb-1">
                <p className="small">Full name:</p>
                <p>{referral.youngPersonFirstName} {referral.youngPersonLastName}</p>
            </div>

            <div className="mb-1">
                <p className="small">Has the young person agreed to this referral?</p>

                {referral.typeformData.youngPersonHasAgreedTheReferral ?
                    <p className="d-flex align-items-center">
                        <CheckCircle size={18} className="mr-1" /> Yes
                    </p>
                    :
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> No
                    </p>
                }
            </div>

            <div className="mb-1">
                <p className="small">Date of birth:</p>
                <p>{referral.typeformData?.youngPersonDateOfBirth ? moment(referral.typeformData?.youngPersonDateOfBirth).format('DD/MM/YYYY') : '-'}</p>
            </div>

            <div className="mb-2">
                <p className="small">Gender</p>
                <p className="d-flex align-items-center">
                    <CheckCircle size={18} className="mr-1" /> {referral.typeformData?.youngPersonGender}
                </p>
            </div>

            <div className="mb-1">
                <p className="small">Ethnicity:</p>
                <p>{referral.youngPersonEthnicity || '-'}</p>
            </div>

            <div className="mb-1">
                <p className="small">Languages spoken:</p>
                <p>{referral.typeformData?.youngPersonLanguagesSpoken || '-'
                }</p>
            </div>

            <div className="mb-1">
                <p className="small">Home language:</p>
                <p>{referral.typeformData?.youngPersonHomeLanguage || '-'}</p>
            </div>

            <div className="mb-1">
                <p className="small">Is the YP's English fluent?</p>
                {referral.typeformData?.youngPersonFluentInEnglish ?
                    <p className="d-flex align-items-center">
                        <CheckCircle size={18} className="mr-1" /> Yes
                    </p>
                    :
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> No
                    </p>
                }
            </div>

            <div className="mb-1">
                <p className="small">YP Address:</p>
                {noYpAddress ? <p>None provided</p> :
                    <>  {addressParts.map((part, index) => (
                        <p key={index}>
                            {part}
                            {index < addressParts.length - 1 && ","}
                            <br />
                        </p>
                    ))}
                    </>
                }
            </div>

        </>
    )
}

export const Family = ({ referral }) => {
    return (
        <>
            <div className="row mb-2">
                <div className="col-md-6">
                    <p className="uppercase bold small text-muted-2">Parent/guardian 1</p>
                    <div className="mb-1">
                        <p className="small">Full name:</p>
                        <p>{referral.typeformData?.parent1Firstname} {referral.typeformData?.parent1Lastname}</p>
                    </div>
                    <div className="mb-1">
                        <p className="small">Phone:</p>
                        <p>{referral.typeformData?.parent1PhoneNumber || '-'}</p>
                    </div>
                    <div className="mb-1">
                        <p className="small">Email:</p>
                        <p>{referral.typeformData?.parent1Email || '-'}</p>
                    </div>
                    <div className="mb-1">
                        <p className="small">Address:</p>
                        <p>
                            {referral.typeformData?.parent1Address && <>{referral.typeformData?.parent1Address + ', '} <br /></>}
                            {referral.typeformData?.parent1Address2 && <>{referral.typeformData?.parent1Address2 + ', '} <br /></>}
                            {referral.typeformData?.parent1City && <>{referral.typeformData?.parent1City + ', '} <br /></>}
                            {referral.typeformData?.parent1County && <>{referral.typeformData?.parent1County + ', '} <br /></>}
                            {referral.typeformData?.parent1Country && <>{referral.typeformData?.parent1Country + ', '} <br /></>}
                            {referral.typeformData?.parent1Zipcode}
                        </p>
                    </div>

                    <div className="mb-1">
                        <p className="small">First language:</p>
                        <p>{referral.typeformData?.parent1FirstLanguage || '-'}</p>
                    </div>

                    <div className="mb-1">
                        <p className="small">Interpreter required:</p>
                        {referral.typeformData?.parent1NeedsInterpreter ?
                            <p className="d-flex align-items-center">
                                <CheckCircle size={18} className="mr-1" /> Yes
                            </p>
                            :
                            <p className="d-flex align-items-center">
                                <Slash size={18} className="mr-1" /> No
                            </p>
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <p className="uppercase bold small text-muted-2">Parent/guardian 2</p>
                    <div className="mb-1">
                        <p className="small">Full name:</p>
                        <p>{referral.typeformData?.parent2Firstname} {referral.typeformData?.parent2Lastname}</p>
                    </div>
                    <div className="mb-1">
                        <p className="small">Phone:</p>
                        <p>{referral.typeformData?.parent2Phone || '-'}</p>
                    </div>
                    <div className="mb-1">
                        <p className="small">Email:</p>
                        <p>{referral.typeformData?.parent2Email || '-'}</p>
                    </div>
                    <div className="mb-1">
                        <p className="small">Address:</p>
                        <p>
                            {referral.typeformData?.parent2Address && <>{referral.typeformData?.parent2Address + ', '} <br /></>}
                            {referral.typeformData?.parent2Address2 && <>{referral.typeformData?.parent2Address2 + ', '} <br /></>}
                            {referral.typeformData?.parent2City && <>{referral.typeformData?.parent2City + ', '} <br /></>}
                            {referral.typeformData?.parent2County && <>{referral.typeformData?.parent2County + ', '} <br /></>}
                            {referral.typeformData?.parent2Country && <>{referral.typeformData?.parent2Country + ', '} <br /></>}
                            {referral.typeformData?.parent2Zipcode}
                        </p>
                    </div>

                    <div className="mb-1">
                        <p className="small">First language:</p>
                        <p>{referral.typeformData?.parent2FirstLanguage || '-'}</p>
                    </div>

                    <div className="mb-1">
                        <p className="small">Interpreter required:</p>
                        {referral.typeformData?.parent2NeedsInterpreter ?
                            <p className="d-flex align-items-center">
                                <CheckCircle size={18} className="mr-1" /> Yes
                            </p>
                            :
                            <p className="d-flex align-items-center">
                                <Slash size={18} className="mr-1" /> No
                            </p>
                        }
                    </div>
                </div>
            </div>



            <p className="uppercase bold small text-muted-2">Family structure</p>
            <div className="mb-1">
                <p className="small">Who lives with YP:</p>
                <p>{referral.typeformData?.familyStructure || '-'}</p>
            </div>
        </>
    )
}


export const Agencies = ({ referral }) => {
    return (
        <>

            <div className="mb-2">
                <p className="small">Is the YP currently involved with any of the following agencies?</p>
                {!referral.typeformData?.ypCurrentlyInvolvedWithAgencies || referral.typeformData?.ypCurrentlyInvolvedWithAgencies?.length === 0 ?
                    <p>-</p> :
                    referral.typeformData?.ypCurrentlyInvolvedWithAgencies?.map(el =>
                        <p key={el} className="d-flex align-items-center">
                            <CheckCircle size={18} className="mr-1" /> {el}
                        </p>
                    )}
            </div>

            <div className="mb-1">
                <p className="small">Has the YP been involved with any of the following agencies in the past?</p>
                {!referral.typeformData?.ypInvolvedWithAgenciesInThePast || referral.typeformData?.ypInvolvedWithAgenciesInThePast?.length === 0 ?
                    <p>-</p> :
                    referral.typeformData?.ypInvolvedWithAgenciesInThePast?.map(el =>
                        <p key={el} className="d-flex align-items-center">
                            <CheckCircle size={18} className="mr-1" /> {el}
                        </p>
                    )}
            </div>

            <div className="mb-1">
                <p className="small">Contact details of agencies:</p>
                <p>{referral.typeformData?.agenciesContacted || '-'}</p>
            </div>
        </>
    )
}

export const Risks = ({ referral }) => {
    return (
        <>
            <div className="mb-1">
                <p className="small">Risk from others</p>
                {!referral.typeformData?.riskFromOthers || referral.typeformData?.riskFromOthers?.length === 0 ?
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> None selected
                    </p> :
                    referral.typeformData?.riskFromOthers?.map(el =>
                        <p key={el} className="d-flex align-items-center">
                            <CheckCircle size={18} className="mr-1" /> {el}
                        </p>
                    )}
            </div>

            <div className="mb-1">
                <p className="small">Risk to themselves</p>
                {!referral.typeformData?.riskToThemselves || referral.typeformData?.riskToThemselves?.length === 0 ?
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> None selected
                    </p> :
                    referral.typeformData?.riskToThemselves?.map(el =>
                        <p key={el} className="d-flex align-items-center">
                            <CheckCircle size={18} className="mr-1" /> {el}
                        </p>
                    )}
            </div>

            <div className="mb-1">
                <p className="small">Please state any significant health conditions that impact the young person</p>
                <p>{referral.typeformData?.significantHealthConditions || '-'}</p>
            </div>

            <div className="mb-1">
                <p className="small">Are they taking medication?</p>
                {referral.ypTakingMedication ?
                    <p className="d-flex align-items-center">
                        <CheckCircle size={18} className="mr-1" /> Yes
                    </p>
                    :
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> No
                    </p>
                }
            </div>

            <div className="mb-1">
                <p className="small">Does the YP have any emotional or behavioral problems?</p>
                {referral.typeformData?.ypHasEmotionalOrBehavioralIssues ?
                    <p className="d-flex align-items-center">
                        <CheckCircle size={18} className="mr-1" /> Yes
                    </p>
                    :
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> No
                    </p>
                }

            </div>

            <div className="mb-1">
                <p className="small">Does the YP have any mental health issues?</p>

                {referral.typeformData?.ypHasMentalHealthIssues ?
                    <p className="d-flex align-items-center">
                        <CheckCircle size={18} className="mr-1" /> Yes
                    </p>
                    :
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> No
                    </p>
                }
            </div>

            <div className="mb-1">
                <p className="small">Is there a formal diagnosis?</p>
                {referral.typeformData?.ypHasADiagnostic ?
                    <p className="d-flex align-items-center">
                        <CheckCircle size={18} className="mr-1" /> Yes
                    </p>
                    :
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> No
                    </p>
                }
            </div>

            <div className="mb-1">
                <p className="small">Does the YP have learning difficulties</p>
                {referral.typeformData?.ypHasLearningDifficulties ?
                    <p className="d-flex align-items-center">
                        <CheckCircle size={18} className="mr-1" /> Yes
                    </p>
                    :
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> No
                    </p>
                }
            </div>

            <div className="mb-1">
                <p className="small">Is the YP aware of the process of Disclosure?</p>
                {referral.typeformData?.ypAwareOfProcessOfDisclosure ?
                    <p className="d-flex align-items-center">
                        <CheckCircle size={18} className="mr-1" /> Yes
                    </p>
                    :
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> No
                    </p>
                }
            </div>
        </>
    )
}

export const Mentor = ({ referral }) => {
    return (
        <>
            <div className="mb-1">
                <p className="small">Has the YP ever been subject of a CP plan?</p>
                <p className="d-flex align-items-center">
                    {referral.typeformData?.ypWasSubjectOfCpPlan ? <> <CheckCircle size={18} className="mr-1" /> Yes</> :
                        <><Slash size={18} className="mr-1" /> No</>
                    }
                </p>
            </div>

            <div className="mb-1">
                <p className="small">Identify reasons behind this referral:</p>
                <p className="d-flex align-items-center">
                    {referral.typeformData?.referralReason || '-'}
                </p>
            </div>


            <div className="mb-1">
                <p className="small">Phone call required to support engagement prior to first appointment</p>
                {referral.typeformData?.phoneCallRequired ?
                    <p className="d-flex align-items-center">
                        <CheckCircle size={18} className="mr-1" /> Yes
                    </p>
                    :
                    <p className="d-flex align-items-center">
                        <Slash size={18} className="mr-1" /> No
                    </p>
                }
            </div>

            <div className="mb-1">
                <p className="small">Mentor preferred</p>
                <p className="d-flex align-items-center">
                    {referral.typeformData?.mentorPreferred || '-'}
                </p>
            </div>

            <div className="mb-1">
                <p className="small">Any special condideration:e.g. timing of appointments due to school, work, etc</p>
                <p className="d-flex align-items-center">
                    {referral.typeformData?.specialConsideration || '-'}
                </p>
            </div>
        </>
    )
}